<template>
  <div>
    <v-card :min-height="$vuetify.breakpoint.mobile ? '' :'400'" flat>

      <v-card-text class="pa-0">

        <div v-if="isLoading">
          <v-skeleton-loader type="list-item-avatar"/>
          <v-skeleton-loader type="list-item-avatar"/>
          <v-skeleton-loader type="list-item-avatar"/>
          <v-skeleton-loader type="list-item-avatar"/>
        </div>

        <div v-else>
          <v-card outlined class="rounded mx-3 my-5" v-if="can('supplier')">
            <h5 class="font-weight-medium pa-3 grey--text text--darken-1">
              Menu
            </h5>
            <v-list nav>
              <v-list-item color="primary" to="/partner-vouchers/stores">
                <v-list-item-avatar size="32">
                  <v-icon>mdi-shopping-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Gestion des boutiques</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item color="primary" to="/partner-vouchers/statistics">
                <v-list-item-avatar size="32">
                  <v-icon>mdi-chart-pie</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Statistiques globale</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>

          <v-card outlined class="rounded mx-3">

            <h5 class="font-weight-medium pa-3 grey--text text--darken-1">
              Mes Boutiques
            </h5>
            <v-list nav>
              <v-list-item v-for="(store,i) in $store.state.stores"
                           :key="i"
                           :to="'/partner-vouchers/store/'+store.id"
                           color="primary">

                <v-list-item-avatar size="32" color="primary">
                  <v-icon color="white">mdi-storefront-outline</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ store.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

          </v-card>


        </div>


      </v-card-text>

    </v-card>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      isLoading: false,
      fileUrl: process.env.VUE_APP_FILE_URL,
    }
  },
  methods: {
    getStores() {
      this.isLoading = true
      HTTP.get('/v1/partner-vouchers/stores').then(res => {
        this.isLoading = false
        let stores = res.data.data
        this.$store.commit('stores', stores)
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },

  created() {
    if (this.$store.state.stores.length === 0) {
      this.getStores()
    }
  },
}
</script>

<style scoped>

</style>